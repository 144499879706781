body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgb(139, 139, 139);
  border-radius: 10px;
}


.actions-item-card .ant-card-actions {
  opacity: 0;
  ;
  height: 0;
  transition: opacity 0.5s, height 0.4s ease-in-out;
}

.actions-item-card:hover .ant-card-actions {
  opacity: 1;
  height: 40px;
  transition: opacity 0.5s, height 0.4s ease-in-out;

}


/**********************************
Editor  Ruler
/**********************************/


.ruler {
  background-color: #ffffff;
  background-image: linear-gradient(90deg,
      rgba(73, 73, 73, 0.5) 0,
      rgba(73, 73, 73, 0.5) 2%,
      transparent 2%),
    linear-gradient(180deg, #ffffff 50%, transparent 50%),
    linear-gradient(90deg,
      transparent 50%,
      rgba(73, 73, 73, 0.5) 50%,
      rgba(73, 73, 73, 0.5) 52%,
      transparent 52%),
    linear-gradient(180deg, #ffffff 70%, transparent 70%),
    linear-gradient(90deg,
      transparent 10%,
      rgba(73, 73, 73, 0.4) 10%,
      rgba(73, 73, 73, 0.4) 12%,
      transparent 12%,

      transparent 20%,
      rgba(73, 73, 73, 0.4) 20%,
      rgba(73, 73, 73, 0.4) 22%,
      transparent 22%,

      transparent 30%,
      rgba(73, 73, 73, 0.4) 30%,
      rgba(73, 73, 73, 0.4) 32%,
      transparent 32%,

      transparent 40%,
      rgba(73, 73, 73, 0.4) 40%,
      rgba(73, 73, 73, 0.4) 42%,
      transparent 42%,

      transparent 60%,
      rgba(73, 73, 73, 0.4) 60%,
      rgba(73, 73, 73, 0.4) 62%,
      transparent 62%,

      transparent 70%,
      rgba(73, 73, 73, 0.4) 70%,
      rgba(73, 73, 73, 0.4) 72%,
      transparent 72%,

      transparent 80%,
      rgba(73, 73, 73, 0.4) 80%,
      rgba(73, 73, 73, 0.4) 82%,
      transparent 82%,

      transparent 90%,
      rgba(73, 73, 73, 0.4) 90%,
      rgba(73, 73, 73, 0.4) 92%,
      transparent 92%);
  background-size: 50px 20px;
  background-repeat: repeat-x;
  min-height: 20px;

  white-space: nowrap;
  font-size: 0;
  margin: 0;
  padding: 0;
}

.ruler-x {
  font-size: 9px;
  padding-top: 2px;
  display: inline-block;
  width: 50px;
  text-indent: 3px;
}

.ruler-x:last-child {
  width: auto;
}


.syntax-highlighter {
  margin: 0 !important;
  min-height: 4rem;
  min-height: 300px;
}

.syntax-highlighter code {
  white-space: pre-wrap !important
}

.code-text-editor:focus {
  outline: none !important;
}

.ae-ui * {
  z-index: 1001;

}


#content-editor:focus-visible,
.content-editor:focus-visible {
  outline: none;
}

.icons-svg svg {
  width: 100%;
  height: 100%;
}

#icons-container> :first-of-type {
  overflow-x: hidden !important;
}


.ant-menu-light .ant-menu-item-selected {
  color: #101010 !important;
}

.style-item-panel:not(.ant-collapse-item-active):hover {
  transform: translateX(20px);
  /* Zoom effect */
  font-weight: bold;
  /* Bold text */
  background-color: #f3f3f3;
  transition: all 0.1s ease-in-out;
}


.ac-nav-tree-item {
  margin: 0.4rem 0 0 0.4rem;
  cursor: pointer;
  border-radius: 5px;
}

.ac-nav-tree-item .ant-radio-wrapper-checked {
  color: #f88a24;
  font-size: 600;
}


details {
  position: relative;
  width: auto;


  overflow: hidden;
  transition: all 0.1s ease;
  line-height: 1.2;
}


details>summary {
  position: relative;
  border-radius: 5px;
  height: 100%;
  color: rgb(0, 0, 0);
  padding: 0.1em 0.5em 0.2em;
}
summary {
  display: flex;
  align-items: center;
  font-size: 14px;
  width: 100%;
}

details[open] > summary {
  
}


details[open] > summary:before {
  content: "▼"; /* Custom expand icon */
  color: #2fc1ff;

  margin-right: 0.5em;
}

details > summary:before {
  content: "▶"; /* Custom collapse icon */
  font-size: 12px;

  margin-right: 0.7em;
}

details[open] {
  height: auto;
  transition: all 1.2s ease;
}

details:not([open]) .ac-nav-tree-item {
  height: 0px !important;
}

.tree-node-text {
  overflow: hidden;
  white-space: nowrap; /* Prevent text from wrapping */
  text-overflow: ellipsis; /* Display ellipsis (...) when text overflows */
  max-width: 102px; /* Ensure the text doesn't exceed the available space */
}